import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';

export const welcomeGuard: CanActivateFn = (_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
	const router = inject(Router);
	return welcomeCheck(router);
};
async function welcomeCheck(router: Router): Promise<boolean | UrlTree> {
	const exists = await inject(OKTA_AUTH).session.exists();
	if (exists === true) {
		return router.parseUrl('/home');
	}
	return true;
}
